.Pagination_wrap {
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
  grid-column: 1/13;
  border-top: solid 1px var(--br-grey);
  border-bottom: solid 1px var(--br-grey);
  padding: 60px 0px;
  display: -ms-grid;
  display: grid;
}

.Pagination_disabled {
  pointer-events: none;
  opacity: 0.5;
}

.Pagination_previous::before {
  content: "\f053";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 8px;
  color: var(--consumer-pink);
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
}

.Pagination_pageCount {
  -ms-grid-column: 7;
  grid-column: 7/7;
  -ms-flex-pack: center;
  justify-content: center;

  color: var(--charcoal-grey);
  font-size: 22px;
}

.Pagination_pipe {
  color: var(--br-grey);
}

.Pagination_next {
  position: relative;
  -ms-grid-column: 12;
  -ms-grid-column-span: 1;
  grid-column: 12/13;
  text-align: right;
  -ms-transform: translateX(-18px);
  transform: translateX(-18px);
}

.Pagination_next::before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;

  position: absolute;
  right: 0;
  top: 2px;
  -ms-transform: translateX(calc(100% + 8px));
  transform: translateX(calc(100% + 8px));

  color: var(--consumer-pink);
}

@media all and (max-width: 1024px) {
  .Pagination_previous {
    -ms-transform: translateX(15px);
    transform: translateX(15px);
  }
  .Pagination_next {
    -ms-transform: translateX(-33px);
    transform: translateX(-33px);
  }
}
